/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { Homepage } from "../../../components/homepage"

export const pageQuery = graphql`
  query lodz {
    file(relativePath: { eq: "parking-lotnisko-lodz-front.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 585, height: 439)
      }
    }
    posts: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___order_parking] }
      filter: { frontmatter: { template: { eq: "parking-post" } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            slug
            title
            isParkVia
            doNotShowOnMainPage
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 345, height: 260)
              }
            }
          }
        }
      }
    }
  }
`

const frontmatter = {
  template: "index-page",
  slug: "/",
  title: "Parkingi Lotnisko Łódź",
  tagline: "Łódź, Polska",
  featuredImage: "/assets/parking-lotnisko-lodz-front.jpeg",
  cta: {
    ctaText: "Wyszukaj parking",
    ctaLink: "#search",
  },
}

const HomePage = ({ data }) => (
  <Homepage
    frontmatter={frontmatter}
    data={data}
    descriptionComponent={
      <p>
        <b>Adres:</b> Maczka 35, 94-328 Łódź Lotnisko Reymonta Łódź-Lublinek
        Port Lotniczy Łódź im. Władysława Reymonta – międzynarodowy port
        lotniczy, zlokalizowany 6 km od centrum Łodzi. Działał nieprzerwanie od
        września 1925. Do końca lat 50. był ważnym węzłem komunikacyjnym w
        Polsce, lecz z bliżej nieokreślonych powodów został zamknięty. Pod
        koniec lat 90
      </p>
    }
  >
    <h2>
      <strong>Jak znaleźć tani parking przy lotnisku w Łodzi</strong>
    </h2>
    <p>
      Jeśli szukasz oszczędności na parkingu w pobliżu lotniska w Łodzi, to mamy
      świetną wiadomość. Nie będziesz musiał płacić bomby za miejsce parkingowe.
      W rzeczywistości możesz zaparkować już za 50 zł! Jeśli to nie brzmi zbyt
      źle, pamiętaj, że niektóre z tych tanich parkingów znajdują się tuż przy
      ruchliwych drogach, gdzie łatwo zobaczyć przejeżdżające samochody i
      wycelować w najlepsze miejsce. Jeśli mieszkasz lub pracujesz w pobliżu
      lotniska w Łodzi, może warto spojrzeć na podjęcie transportu publicznego,
      tak, że jesteś bliżej miejsca docelowego zamiast chodzić lub wziąć
      taksówkę. Na naszej stronie znajdziesz najtańsze opcje parkowania w
      pobliżu lotniska w Łodzi
    </p>
    <h2>Unikaj kolejek i parkuj z wyprzedzeniem</h2>
    <p>
      Jedną z najważniejszych rzeczy do zrobienia podczas parkowania przy
      lotnsku Gdańsk jest uniknięcie czekania w długiej kolejce. Dlatego mamy
      dla Ciebie kilka świetnych wskazówek. Istnieje kilka sposobów, aby to
      zrobić, ale najbardziej efektywnym sposobem jest wczesna rezerwacja. Jeśli
      masz możliwość zaparkowania z wyprzedzeniem, zalecamy to zrobić. Często
      taniej jest zaparkować z wyprzedzeniem
    </p>
    <h2>Darmowy transfer</h2>
    <p>
      Nasze parkingi oferują bezpłatny transfer na lotnisko i z lotniska.
      Przejazdy te są częste i oferują świetny sposób na dotarcie na lotnisko.
    </p>
    <h2>Parking Pienista 32</h2>
    <p>
      Jest to prawdopodobnie najczęściej wybierany parking w pobliżu lotniska.
      Jest mniej kosztowny i zapewnia najlepsze wrażenia z parkowania. Jeśli
      szukasz szybkiego i łatwego miejsca do zaparkowania samochodu wybierz
      Pienista 32
    </p>
    <h2></h2>
  </Homepage>
)

export default HomePage
